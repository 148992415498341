import './styles/app.scss';
import $ from 'jquery';
// import * as bootstrap from 'bootstrap';


$(".service-card").click(() => window.open("https://calendly.com/sgnextleveltennis"))

function createPoster (imageUrl, location, expiration) {
    const posterViews = window.localStorage.getItem("posterView") || 0;

    if (posterViews && Number(posterViews) >= 3) {
        return;
    }

    const popupContainer = document.createElement("div");
    popupContainer.id = "popup-container";
    popupContainer.className = "d-flex justify-content-center align-items-center";
    popupContainer.onclick = () => document.body.removeChild(popupContainer);

    const popupContent = document.createElement("div");
    popupContent.className = "col-md-6 col-sm-12";

    const posterImage = document.createElement("img");
    posterImage.src = imageUrl;
    posterImage.className = "col-4 w-100";
    posterImage.onclick = () => window.open(location, "_blank");

    popupContent.appendChild(posterImage);
    popupContainer.appendChild(popupContent);

    document.body.appendChild(popupContainer);
    window.localStorage.setItem("posterView", Number(Number(posterViews) + 1).toString());
}

(() => {
    const imageUrl = new URL(
        'images/swingforcauseposter.jpg',
        import.meta.url
    );
    window.onload = function () {
        // createPoster(imageUrl, "https://secure.actblue.com/donate/tennis56");
    }
})()